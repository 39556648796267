<script>
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import 'dayjs/locale/zh-cn'
import { dateFormat } from '@/utils'
import { getCalendarList } from '@/services/appointment'
const date = dayjs()
dayjs.extend(weekday)
dayjs.locale('zh-cn')
export default {
  props:{
    initDate:{
      type: [Date,Object,Function,String,Number],
      default: ()=>date
    },
    appointmentType:{
      type: String,
      default:''
    },
    vinCode:{
      type: String,
      default:''
    },
    tableType:{
      type: String,
      default:''
    },
    roleCode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      nDate: date,
      dateData:{        
        0:{
          title:this.$t('周一'),
          date:'',
          day: '',
          className:'',
          text:''
        },
        1:{
          title:this.$t('周二'),
          date:'',
          day: '',
          className:'',
          text:''
        },
        2:{
          title:this.$t('周三'),
          date:'',
          day: '',
          className:'',
          text:''
        },
        3:{
          title:this.$t('周四'),
          date:'',
          day: '',
          className:'',
          text:''
        },
        4:{
          title:this.$t('周五'),
          date:'',
          day: '',
          className:'',
          text:''
        },
        5:{
          title:this.$t('周六'),
          date:'',
          day: '',
          className:'',
          text:''
        },
        6:{
          title: this.$t('周日'),
          date:'',
          day: '',
          className:'',
          text:''
        },
      },
      num:{}
    }
  },
  watch:{
    initDate:{
      handler(val){
        // if (val){
        this.initList()
        // }
      },
      immediate: true
    }
  },
  mounted() {

  },
  methods:{
    initList(){
      let { initDate, dateData } = this
      if (!initDate)initDate = dayjs()
      if (initDate){
        for (const key in dateData) {
          const item = dateData[key]
          item.text = ''
          item.className = ''
          const date = dayjs(initDate).weekday(key)
          this.nDate.isSame(dayjs(date), 'date') && (item.text = this.$t('今'))
          item.value = dateFormat(date,'YYYY-MM-DD')
          item.day = dateFormat(date,'D')
          // dayjs(initDate).isSame(dayjs(date), 'date') && (item.className = 'van-calendar__selected-day')
        }
        if (this.tableType !== 'TO_BE_ASSIGN') { // 待分配不更新数据
          this.getAppointmentCalendar()
        }
      }
    },
    getSameClassName(date) {
      return this.initDate? dayjs(this.initDate).isSame(dayjs(date),'date'):''
    },
    changeDate(item){
      this.$emit('change',this.getSameClassName(item.value)?'':item.value,item)
    },
    getAppointmentCalendar() {
      const { appointmentType,vinCode,dateData,roleCode } = this
      getCalendarList({
        roleCode,
        appointmentType,vinCode,
        startDate:  dateFormat(dateData[0].value,'YYYY-MM-DD'),
        endDate: dateFormat(dateData[6].value,'YYYY-MM-DD')
      }).then((res) => {
        this.num = res  
      })
    },
  }
}
</script>

<template>
  <div class="dateList">
    <div class="van-calendar__header">
      <div class="van-calendar__weekdays">
        <div v-for="(item,key) in dateData" :key="key" class="van-calendar__weekday">{{ item.title }}</div>
      </div>
    </div>
    <div class="van-calendar__days">
      <div v-for="(item,key) in dateData" :key="key" class="li van-calendar__day" @click.stop="changeDate(item)">
        <div :class="{nowDay:!!item.text,'van-calendar__selected-day':!!getSameClassName(item.value)}">
          <span style="font-weight: 600;">{{ item.text || item.day }}</span>
          <div v-if="num[item.value]" class="van-calendar__bottom-info" style="bottom: 4px;">
            <span class="dot">{{num[item.value]}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.dateList{
  background-color: #fff;
  .van-calendar__days{
    .li{
      height: 60px;
      >div{
        min-width: 46px;
      }
      .van-calendar__selected-day,.nowDay{
        height: 100%;
      }
      .dot{
        color: @yellow-text;
      }
      .nowDay{
        line-height: 60px;
        background: #E5E5E5;
        border-radius: 6px;
      }
    }
  }
}
</style>
