<script>
import TestDrive from './testDrive.vue'
import Shop from '@/modules/appointmentShop/index.vue'
export default {
  name:'Appointment',
  components:{ TestDrive,Shop },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit('app/addKeepAlive', 'Appointment')
    })
  },
  data() {
    return {
      type:0,
      tabs:[
        {
          name: this.$t('预约试驾'),
          value: 0,
          componentName:'TestDrive'
        },
        {
          name: this.$t('预约到店'),
          value: 1,
          componentName:'Shop'
        }
      ],
      outsideTabs:[]
    }
  },
  created() {
    const { tabs } = this.$route.query
    if (tabs){
      this.outsideTabs = tabs.split(',')
    }
    this.type = Number(this.outsideTabs[0])
  },
  activated() {
    this.$refs[this.tabs.find(({ value }) => value == this.type)?.componentName][0].$refs.list.onRefresh()
    this.$refs[this.tabs.find(({ value }) => value == this.type)?.componentName][0].getAppointmentNum()
  },
  methods:{
    onTabChange() {
      this.outsideTabs = []
    }
  }
}
</script>
<template>
  <van-tabs v-model="type" class="tabs1" @change="onTabChange">
    <van-tab
      v-for="item in tabs"
      :key="item.value"
      :title="item.name"
      :name="item.value"
    >
    <component :is="item.componentName" v-if="item.value === type" :ref="item.componentName" :outsideTab="outsideTabs[1] || ''" />
    </van-tab>
  </van-tabs>
</template>
<style lang="less" scoped>
  /deep/ .van-tabs__wrap{
    position: sticky;
    z-index: 20;
    top: 0
  }
</style>
