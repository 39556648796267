import request from '@/utils/request'
import SERVER from './server'
import { thenResolve } from '@/utils/index'

// 翻页
export const getAppointmentPage = (params={}) =>{
  return request.post(`${SERVER.LEAD_SERVICE}/v1/appointment/toStore/list/page`,params).then(thenResolve)
}

// 获取页签预约数量信息
export const getAppointmentNum = (params={}) =>{
  return request.post(`${SERVER.LEAD_SERVICE}/v1/appointment/toStore/list/table`,params).then(thenResolve)
}
