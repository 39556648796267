<script>
import dayjs from 'dayjs'
import SearchBarNew from '@/components/search-bar-new'
import Scroll from '@/components/scroll'
import Card from './component/card.vue'
import List from '@/components/baseList/list.vue'
import calendarVue from '@/modules/appointment/component/calendar.vue'
import weekdayCalendar from '@/modules/appointment/component/weekdayCalendar.vue'
import FilterPanel from '@/components/filter-panel'
import { dateFormat,arrayFormat } from '@/utils'
import formatParams from '@/utils/formatParams'
import { mapGetters } from 'vuex'
import { getAppointmentPage,getAppointmentNum } from '@/services/appointmentShop'
import baseDataServices from '@/services/baseDataServices'
import filterIcon from '@/images/filter.svg'
import filterActiveIcon from '@/images/filter_active.svg'
import calendarIcon from '@/images/calendar.svg'
import { v4 as uuidv4 } from 'uuid'
export default {
  name:'AppointmentShop',
  components:{ SearchBarNew,Scroll,Card,List,calendarVue,FilterPanel,weekdayCalendar },
  props:{
    outsideTab:{
      type: [String,Number],
      default:''
    }  
  },
  data() {
    const date = dayjs()
    const that = this
    return {
      filterIcon,
      filterActiveIcon,
      calendarIcon,
      showPopup: false,
      filterPanelVisible: false,      
      planArriveDate:date,
      tableType: 'ALL',
      tabNum: {},
      tabs:[
        {
          name: this.$t('全部'),
          value: 'ALL',
          get num() {
            return that.tabNum.allCount
          },
        },{
          name: this.$t('待到店'),
          value: 'NO_ARRIVAL',
          get num() {
            return that.tabNum.noArrivalCount
          },
        },{
          name: this.$t('已到店'),
          value: 'ARRIVAL',
          get num() {
            return that.tabNum.arrivalCount
          },
        },{
          name: this.$t('已取消'),
          value: 'CANCELED',
          get num() {
            return that.tabNum.canceledCount
          },
        },{
          name: 'No show',
          value: 'NOSHOW',
          get num() {
            return that.tabNum.noShowCount
          },
        }
      ],
      // tabs: [
      //   {
      //     name: '全部',
      //     value: 'ALL',
      //     get num() {
      //       return that.tabNum.allCount
      //     },
      //   },
      //   {
      //     name: '未到店',
      //     value: 'NO_ARRIVAL',
      //     get num() {
      //       return that.tabNum.noArrivalCount
      //     },
      //   },
      //   {
      //     name: '超时未到店',
      //     value: 'TIMEOUT_NO_ARRIVAL',
      //     get num() {
      //       return that.tabNum.timeoutNoArrivalCount
      //     },
      //   },
      //   {
      //     name: '已取消',
      //     value: 'CANCELED',
      //     get num() {
      //       return that.tabNum.canceledCount
      //     },
      //   },
      //   {
      //     name: '已到店',
      //     value: 'ARRIVAL',
      //     get num() {
      //       return that.tabNum.arrivalCount
      //     },
      //   },
      // ],
      filterOptions:[
        {
          id: 1,
          type: 'options',
          label: this.$t('试驾等级'),
          multiple: true,
          field: 'tryDriveLevels', 
          get options() {
            return that.$store.getters.getDictHash(1012) || []
          }
        },
        {
          id: 2,
          type: 'options',
          label: this.$t('意向等级'),
          multiple: true,
          field: 'leadLevels',
          get options() {
            return that.intentionLevelList || []
          }
          // options: [
          //   { id: 'H', label: 'H', value: 'H' },
          //   { id: 'A', label: 'A', value: 'A' },
          //   { id: 'B', label: 'B', value: 'B' },
          //   { id: 'C', label: 'C', value: 'C' },
          //   { id: 'U', label: 'U', value: 'U' },
          // ],
        },
        {
          id: 3,
          type: 'options',
          label: this.$t('来源渠道'),
          multiple: true,
          field: 'channelOneId',
          get options() {
            return that.channelList || []
          }
        },
        {
          id: uuidv4(),
          type: 'options',
          label: '',
          field: 'channelTwoId',
          multiple: true,
          options: [],
        },
        {
          id: uuidv4(),
          type: 'options',
          label: '',
          field: 'channelThreeId',
          multiple: true,
          options: [],
        },{
          id: uuidv4(),
          type: 'options',
          label: '',
          field: 'channelFourId',
          multiple: true,
          options: [],
        },{
          id: uuidv4(),
          type: 'options',
          label: '',
          field: 'channelFiveId',
          multiple: true,
          options: [],
        },
        {
          id: 4,
          type: 'options',
          label: this.$t('销售顾问'),
          multiple: true,
          field: 'followUserIds',
          get options() {
            return that.saleList || []
          }
        }
      ],
      appointmentType:'2001002', // 预约类型(预约试驾:2001001、预约到店:2001002)
      filterParams: {},
      channelList:[],
      saleList:[],
      searchStr:'',
      intentionLevelList:[],
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ]),
    getWeekdayDate() {
      const { planArriveDate } = this
      return planArriveDate? new Date(planArriveDate):''
    }
  },
  watch:{
    userInfo:{
      handler(val){
        if (val && val.dealers && val.dealers[0]){
          this.getStaffList('1014002').then(res=>{
            this.saleList = arrayFormat(res)
          })
        }
      },
      immediate: true,
    }
  },
  created() {
    this.tableType = this.outsideTab || 'ALL'
    // 待办跳过来  是否指定顾问
    if (this.$route.query?.followUserIds){
      this.$set(this.filterParams,'followUserIds',[this.$route.query.followUserIds])
      const that = this
      this.$nextTick(()=>{
        that.$refs.filterPanel.formData.followUserIds = [{ id:this.$route.query.followUserIds }]
      })
    }
    this.getAppointmentNum()
    this.getAllChannelList()
    this.getIntentionLevelList()
  },
  methods:{    
    changeDate(val) {
      this.planArriveDate = val
      this.showPopup = false
      this.tableType = 'ALL'
      this.getAppointmentNum()
      this.onRefresh()
      // setTimeout(()=>{
      //   this.$refs.mycalendar.calendarScrollTop()
      // })
    },
    // 切换tab
    onTabChange(tableType) {
      this.tableType = tableType
      // this.initCalendar()
      this.onRefresh()
      // setTimeout(()=>{
      //   this.calendarScrollTop()
      // })
    },
    onInput(value) {
      this.searchStr = value
    },
    onSearch() {
      // this.getBeTypeCount()
      this.onRefresh()
      this.getAppointmentNum()
      // this.getAppointmentCalendar().then(res=>{
      //   this.planDate = JSON.stringify(res)
      // })
    },
    onFilter(params) {
      // loading.showLoading()
      this.filterParams = formatParams(params, false)
      this.filterPanelVisible = false
      this.onRefresh()
      this.getAppointmentNum()
    },
    // 刷新线索数据
    onRefresh() {
      try {
        this.$refs.list.onRefresh()
        document.getElementById('scrollView').scrollTop = 0
      } catch (error) {

      }
    },
    fetchApi(page) {
      const { currentPage,pageSize } = page
      const { planArriveDate,appointmentType,searchStr,filterParams,tableType } = this
      return getAppointmentPage({
        pageNum: currentPage,
        pageSize,
        search:searchStr,
        ...filterParams,
        appointmentType,
        tableType,
        startDate: dateFormat(planArriveDate,'YYYY-MM-DD'),
        endDate: dateFormat(planArriveDate,'YYYY-MM-DD'),
      })
      // return Promise.resolve({
      //   page: {
      //     currentPage: 1,
      //     pageSize: 20,
      //   },
      //   data: new Array(10),
      //   total: 10,
      // })
    },
    getAppointmentNum() {
      const { planArriveDate,appointmentType,searchStr,filterParams } = this
      getAppointmentNum({
        // appointmentType,
        search:searchStr,
        ...filterParams,
        startDate: dateFormat(planArriveDate,'YYYY-MM-DD'),
        endDate: dateFormat(planArriveDate,'YYYY-MM-DD'),
      }).then(res=>{
        this.tabNum = res
      })
    },
    // 渠道
    getAllChannelList(){
      baseDataServices.getAllChannelList().then(res=>{
        this.channelList = arrayFormat(res)
      })
    },
    // 人员list
    getStaffList(roleLogo) {
      return baseDataServices.getStaffList({ roleLogo, dealerId: this.userInfo.dealers[0]?.id })
    },
    // 车型
    getModelList() {
      baseDataServices.getModelList({}).then(res=>{
        this.modelList = arrayFormat(res,{ name:'name',value:'code' })
      })
    },
    // 意向等级
    getIntentionLevelList() {
      baseDataServices.getIntentionLevelList({}).then(res=>{
        this.intentionLevelList = arrayFormat(res,{ name:'levelName',value:'levelCode' })
      })
    }
  }
}
</script>
<template>
  <div class="page">
    <van-popup v-model="showPopup" position="top" closeable :style="{ height: '70%' }">
      <calendarVue v-if="showPopup" :appointmentType="appointmentType" maxHeight="100%" :init-date="planArriveDate" @change="changeDate"/>
    </van-popup>
    <div style="position: sticky; z-index: 20; top: 44px;">
      <search-bar-new
        :placeholder-text="$t('请输入客户姓名/电话')"
        :showScreen="true"
        @input="onInput"
        @search="onSearch"
      >
        <img :src="Object.values(filterParams).length > 0 ? filterActiveIcon : filterIcon" @click.stop="filterPanelVisible = true"/>
        <img :src="calendarIcon" @click.stop="showPopup = true"/>
      </search-bar-new>
      <div class="my-calendar">
        <weekdayCalendar  :init-date="getWeekdayDate" :tableType="tableType" :appointmentType="appointmentType" @change="changeDate" />
        <!-- <calendarVue ref="mycalendar" :isOpen="false" :isSubtitle="false" :show-mark="false" :isAutoScrollTop="true" :appointmentType="appointmentType" :init-date="planArriveDate" @change="changeDate"/> -->
      </div>
      <div class="tabs">
          <div v-for="(item,index) in tabs" :key="item.value" :class="{current: item.value === tableType}" class="tab" @click="onTabChange(item.value)">{{ item.name + (item.value ? `(${item.num || 0})` : '') }}</div>
      </div>
    </div>
    <!-- <scroll class="scroll" height="auto"> -->
      <list ref="list" :key="tableType || 'ALL'" :fetchApi="fetchApi">
        <template v-slot:default="{ item, index }">
          <card :data="item" :planArriveDate="planArriveDate"/>
        </template>
      </list>
    <!-- </scroll> -->
    <filter-panel ref="filterPanel" v-model="filterPanelVisible" :options="filterOptions" @submit="onFilter" />
  </div>
</template>

<style lang="less" scoped>
.tabs{
  font-size: 13px;
  display: flex;
  overflow-x: auto;
  padding: 8px 16px;
  background: #fff;
  .tab{
    flex: 1 0 auto;
    padding: 4px 12px;
    background-color: #fff;
    border-radius: 50px;
    // box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.08);
    cursor: pointer;
    &:not(:last-child){
      margin-right: 12px;
    }
    &.current{
      background-color: #EED484;
      color: #B9921A;
    }
  }
}
.my-calendar{
  /deep/ .van-calendar__body{
    overflow: hidden;
  }
}
</style>
