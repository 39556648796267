<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
import externalUserIcon from '@/components/card-view/external-user-icon.vue'
import phoneIcon from '@/components/card-view/phone-icon.vue'
import qwIcon from '@/components/card-view/qw-icon.vue'
import wxIcon from '@/components/card-view/wx-icon.vue'
export default {
  components:{ shortConsumerInfo,externalUserIcon,phoneIcon,qwIcon,wxIcon },
  filters:{
    getdectText(val,trip,field,store,dictCode){
      // 不需要查字典
      if (!dictCode) return val
      // 没有值的情况
      if (!trip[field]&&val!==0) return '--'
      const dictHash = store.getters.dictHash
      const getOptionsByDictType = (dictType) => {
        const list = dictHash[dictType] || []
        return list.map(({ code, id, name }) => ({
          id,
          label: name,
          value: code,
        }))
      }
      const options = getOptionsByDictType(dictCode)
      const theOne = options.find(item=>item.value == trip[field])
      return theOne?.label || ''
    }
  },
  props:{
    data:{
      type: Object,
      default:()=>({})
    },
    isTodo: {
      type: Boolean,
      default: false
    },
  },
  computed:{
    getStatusColor() {
      const obj={
        'TIMEOUT_NO_ARRIVAL':'red', // 超时未到店
        'NO_ARRIVAL':'blue', // 未到店
        'CANCELED':'gray', // 已取消
        'ARRIVAL':'green' // 已到店
      }
      return obj[this.data?.tableType] || ''
    },
    // 意向信息
    getIntention() {
      const { intentionSeriesName,intentionModelName } = this.data
      const arr = []
      intentionSeriesName && arr.push(intentionSeriesName)
      intentionModelName && arr.push(intentionModelName)
      return arr.join('/')
    },
    getUserInfo() {
      return this.$store.getters.userInfo
    },
    isShowBtns() {
      const { data, getUserInfo } = this
      if (getUserInfo.type !== 1) return false
      return ['NO_ARRIVAL','TIMEOUT_NO_ARRIVAL'].includes(data.tableType) && (getUserInfo?.authorizeIds.includes(data?.followUserId) || getUserInfo.id == data.authorizedEmployeeId) && getUserInfo?.roleLogos.includes(data.followUserRoleCode)
    }
  },
  methods:{
    // 预约详情
    goDetail(item){
      if (item.leadTransferFlag===1){
        return this.$toast(this.$t('线索已转出至其他门店，无法查看'))
      }
      this.$router.push({
        name:'appointmentDetail',
        query:{
          id: item.id,
          clueId: item.leadId
        }
      })
    },
    goFollow(data,followResult,followMode='') {
      this.$router.push({
        path: '/clue-follow',
        query: {
          id: data.leadId,
          appointmentId: data.id,
          followResult, // '1011005', // 1011005取消预约  1011006修改预约 1011002 继续跟进
          followMode, // 1010003 店内接待
        }
      })
    },
    selectUser(params={}){
      const { avatar,externalUserid,name } = params
      Object.assign(this.data,{
        customerName: name,
        userWeChat:externalUserid,
      })
    },
  }
}
</script>
<template>
  <div class="card">
    <div class="title">{{ this.$t('预约到店日期') }}：{{data.appointmentTime}}
      
      <span v-if="data.tableType === 'TIMEOUT_NO_ARRIVAL'" class="red">{{ this.$t('已超时') }}: {{ data.timeoutTime }}</span>
    </div>
    <div class="info" @click.stop="goDetail(data)">
      <div class="user-status">
        <shortConsumerInfo :data="data" :option="{
          name:'customerName',
          phone:'customerMobile',
          sex:'userSex'
        }" />
        <div :class="[getStatusColor]">{{data.tableType | getdectText(data, 'tableType', $store,'2020')}}</div>
      </div>
      <div class="van-ellipsis">
        <span class="label">{{ this.$t('意向信息') }}： </span>
        <span >{{getIntention || '--'}}</span>
      </div>
      <div class="van-ellipsis" style="display: flex; align-items: center;">
        <span class="label">{{ this.$t('最新记录') }}： </span>
        <span class="followRemark">{{data.followRemark || '--'}}</span>
      </div>
      <div v-if="data.followUserRoleCode" class="van-ellipsis">
        <template v-if="data.followUserRoleCode === '1014001'">
          <span class="label">{{ this.$t('DCC专员') }}： </span>
          <span>{{data.followUserName}}</span>
        </template>
        <template v-else>
          <span class="label">{{ this.$t('销售顾问') }}： </span>
          <span>{{data.followUserName}}</span>
        </template>
      </div>
      <div v-if="!isTodo" class="icons">
        <externalUserIcon v-if="!data.userWeChat" :id="data.leadId" class="icon" @selectUser="selectUser"/>
        <phoneIcon :phone="data.customerMobile" :oneself="data.followUserId === getUserInfo.id" :clueId="data.leadId" class="icon"/>
        <qwIcon v-if="data.userWeChat" :leadId="data.leadId" :externalUserIds="data.userWeChat" class="icon"/>
        <wxIcon v-if="!data.userWeChat" class="icon"/>
      </div>
    </div>
    <div v-if="isTodo" class="bottom bottomNew">
      <slot name="btns" />
    </div>
    <div v-if="isShowBtns && !isTodo" class="bottom">
      <div v-btn-premission="'BTN-TEST-CANCEL'" @click="goFollow(data,'1011005')">{{ this.$t('取消预约') }}</div>
      <div v-btn-premission="'BTN-TEST-EDIT'" class="btn-yellow" @click="goFollow(data,'1011006')">{{ this.$t('修改预约') }}</div>
      <!-- 这个后面会衔接到店接待的跟进页面 -->
      <div v-btn-premission="'BTN-TEST-START'" class="btn-yellow" @click.stop="goFollow(data,'1011002','1010003')">{{ this.$t('到店接待') }}</div>
    </div>
  </div>
</template>
<style lang="less" scoped>
@lineColor: #F3F3F4;
.btn-yellow{
  color: #B9921A;
}
.label{
  color: #7D7D7D;
}
.yellow{
  color:#FFD100;
}
.red{
  color:#E4002C;
}
.blue{
  color:#0040C5;
}
.gray{
  color:#0D171A;
}
.green{
  color: #029640;
}
.card{
  background-color: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.05);
  border-radius: 8px ;
  padding: 16px 12px 12px;
  font-size: 13px;
  .title{
    padding-bottom: 12px;
    border-bottom: 1px solid @lineColor; 
    margin-bottom:8px;
    display: flex;
    justify-content: space-between;
  }
  .ml-16{
    margin-left: 16px;
  }
  .info{
    position: relative;
    .user-status{
      display: flex;
      justify-content: space-between;
    }
    >div{
      margin-bottom: 8px;
    }
    .icons{
      position: absolute;
      bottom:0;
      right:0;
      display:flex;
      .icon{
        margin-left: 16px;
      }
    }
  }
  .bottom{
    border-top: 1px solid @lineColor; 
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    >div{
      padding: 8px 8px 0;
      cursor: pointer;
    }
  }
  .bottomNew{
    border-top: 1px solid @lineColor; 
    display: flex;
    justify-content: flex-end;
  }
  .followRemark{
    display: inline-block;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
