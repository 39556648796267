<script>
import dayjs from 'dayjs'
import SearchBarNew from '@/components/search-bar-new'
import Scroll from '@/components/scroll'
import weekdayCalendar from './component/weekdayCalendar.vue'
import Card from './component/card.vue'
import List from '@/components/baseList/list.vue'
import calendarVue from './component/calendar.vue'
import FilterPanel from '@/components/filter-panel'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import { getAppointmentPage,getAppointmentNum,getTestDriveAssign,driveUpdatePost } from '@/services/appointment'
import { dateFormat,arrayFormat } from '@/utils'
import formatParams from '@/utils/formatParams'
import { mapGetters } from 'vuex'
import baseDataServices from '@/services/baseDataServices'
import { v4 as uuidv4 } from 'uuid'
import filterIcon from '@/images/filter.svg'
import filterActiveIcon from '@/images/filter_active.svg'
import calendarIcon from '@/images/calendar.svg'
import UpdateVue from '@/modules/appointment/update.vue'
import clueServices from '@/services/clueServices.js'
export default {
  name:'AppointmentDrive',
  components:{ SearchBarNew,Scroll,Card,List,calendarVue,FilterPanel,BusinessFilters,weekdayCalendar,UpdateVue },  
  props:{
    outsideTab:{
      type: [String,Number],
      default:''
    }  
  },
  beforeRouteEnter(to, from, next){
    next(vm=> {
      if (from.path === '/salesAssistant' || ['CONFIRMED', 'COMPLETED'].includes(to.query.tableType)) {
        vm.fromSalesAssistant = true
        vm.$store.commit('app/addKeepAlive', 'AppointmentDrive')
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // 如果回到销售助手（店端/总部）、交付助手首页 清空过滤数据
    if (['/delivery-assistant','/salesAssistant','/sales-assistant-portal'].some((item) => { return to.path.includes(item) })) {
      this.filterParams = {}
      this.$refs.filterPanel.reset()
      this.$store.commit('app/deleteKeepAlive', 'AppointmentDrive')
    }
    next()
  },
  data() {
    const date = dayjs()
    const that = this
    return {
      filterIcon,
      filterActiveIcon,
      calendarIcon,
      showPopup: false,
      filterPanelVisible: false,      
      planArriveDate:date,
      tableType: 'ALL',
      tabNum: {},
      tabs:[
        {
          name: this.$t('全部'),
          value: 'ALL',
          get num() {
            return that.tabNum.allCount
          },
        },{
          name: this.$t('待分配'),
          value: 'TO_BE_ASSIGN',
          get num() {
            return that.tabNum.toBeAssignCount
          },
        },{
          name: this.$t('排程待确认'),
          value: 'TO_BE_CONFIRM',
          get num() {
            return that.tabNum.toBeConfirmCount
          },
        },{
          name: this.$t('待试驾'),
          value: 'CONFIRMED',
          get num() {
            return that.tabNum.confirmedCount
          },
        },{
          name: this.$t('试驾中'),
          value: 'DRIVING',
          get num() {
            return that.tabNum.drivingCount
          },
        },{
          name: this.$t('已试驾'),
          value: 'COMPLETED',
          get num() {
            return that.tabNum.completedCount
          },
        },{
          name: this.$t('已取消'),
          value: 'CANCELED',
          get num() {
            return that.tabNum.canceledCount
          },
        },{
          name: 'No show',
          value: 'NOSHOW',
          get num() {
            return that.tabNum.noShowCount
          },
        },
      ],
      filterOptions:[
        {
          id: 1,
          type: 'options',
          label: this.$t('试驾场景'),
          multiple: true,
          field: 'types',
          get options() {
            return that.$store.getters.getDictHash(2002) || []
          }
        },
        {
          id: 2,
          type: 'options',
          label: this.$t('试驾车型'),
          multiple: true,
          field: 'seriesCodes',
          get options() {
            return that.modelList || []
          }
        },
        {
          id: 3,
          type: 'options',
          label: '',
          multiple: true,
          field: 'vehicles',
          options:[]
        },
        {
          id: 5,
          type: 'options',
          label: this.$t('跟进人员'),
          multiple: true,
          field: 'followUserIds',
          get options() {
            return that.dccList || []
          }
        },
        {
          id: 4,
          type: 'options',
          label: this.$t('试驾状态'),
          field: 'statusList',
          multiple: true,
          get options() {
            return that.$store.getters.getDictHash(2003) || []
          }
        },
        {
          id: uuidv4(),
          type: 'options',
          label: this.$t('试驾礼状态'),
          field: 'statusEnum',
          multiple: true,
          options: [
            { id: uuidv4(), label: this.$t('未发送'), value: 'NOT_SEND' },
            { id: uuidv4(), label: this.$t('已发未领'), value: 'NOT_RECEIVE' },
            { id: uuidv4(), label: this.$t('已领取'), value: 'RECEIVED' },
            { id: uuidv4(), label: this.$t('未中奖'), value: 'NOT_LOTTERY' },
            // { id: uuidv4(), label: this.$t('无领取资格'), value: 'UNQUALIFIED' }
          ]
        }
      ],
      searchStr:'',
      appointmentType:'2001001', // 预约类型(预约试驾:2001001、预约到店:2001002)
      filterParams: {},
      dccList:[],
      modelList:[],      
      checkedObj: {},
      showDistribution: false,
      distributionOptions:[
        {
          name: this.$t('销售顾问'),
          type: 'B_PEOPLE_SELECTION',
          field: 'saIdList',
          isSelection: true,
          valueType: 'object',
          shopIds:'',
          height:'80vh',
          roleCodes: '1014002' // 角色对应的code
        },
      ],
      defaultActiveKey:['saIdList'],
      showScheduling: false, // 是否展示排程
      appointmentInfo:{
        appointmentEndTime: '',
        appointmentStartTime: '',
        appointmentTypeCode: '',
        cancelReason: '',
        carNumber: '',
        typeCode: '',
        vinCode: '',
        vehicleCode: '',
        vehicleName: '',
        appointmentTimeShow: '',
        id: ''
      },
      fromSalesAssistant: false,
      roleCode: ''
    }
  },
  computed: {
    ...mapGetters({ userInfo:'userInfo',getMenu:'app/getMenu' }),
    isCheck() {
      return this.tableType === 'TO_BE_ASSIGN'
    },
    getCheckLength() {
      return Object.keys(this.checkedObj).length
    },
    getWeekdayDate() {
      const { planArriveDate } = this
      return planArriveDate? new Date(planArriveDate):''
    }
  },
  watch:{
    userInfo:{
      handler(val){
        if (val && val.dealers && val.dealers[0]){
          this.getStaffList('1014001,1014002').then(res=>{
            this.dccList = arrayFormat(res)
          })
        }
      },
      immediate: true,
    },
    isCheck:{
      handler(val){
        if (!val) this.checkedObj={}
      },
      immediate: true
    },
  },
  created() {    
    const { followUserIds,tableType,roleCode } = this.$route.query
    this.roleCode = roleCode
    if (Array.isArray(followUserIds) ? followUserIds.length > 0 : followUserIds){
      const userIds = Array.isArray(followUserIds) ? followUserIds :[followUserIds]
      // 销售助手跳过来  是否指定顾问
      if (userIds){
        this.$set(this.filterParams,'followUserIds',userIds)
        const that = this
        this.$nextTick(()=>{
          that.$refs.filterPanel.formData.followUserIds = []
          userIds.forEach(id=>{
            that.$refs.filterPanel.formData.followUserIds.push({
              id: id,value: id
            }) 
          })
        })
      }
    }
    this.tableType = tableType ? tableType : 'ALL'
    
    this.getAppointmentNum()
    this.getAllSeriesModels()
  },
  methods:{    
    changeDate(val) {
      this.planArriveDate = val
      this.showPopup = false
      this.checkedObj={}
      this.tableType = 'ALL'
      if (this.tableType !== 'TO_BE_ASSIGN') { // 待分配不更新数据
        this.onRefresh()
        this.getAppointmentNum()
      }
    },
    // 切换tab
    onTabChange(tableType) {
      this.tableType = tableType
      this.checkedObj={}
      this.onRefresh()
    },
    onInput(value) {
      this.searchStr = value
    },
    onSearch() {
      this.onRefresh()
      this.getAppointmentNum()
    },
    checkItem(item){
      if (this.checkedObj[item.id]){
        this.$delete(this.checkedObj,item.id)
      } else {
        this.$set(this.checkedObj,item.id,item)
      }
    },
    // 全选
    checkAll() {
      const { list } = this.$refs.list
      const checkedObj = {}
      if (list && list.length){
        list.map((item)=>{
          checkedObj[item.id] = item
        })
        this.checkedObj = checkedObj
      }
    },
    next() {      
      if (Object.keys(this.checkedObj).length){
        this.showDistribution = true
      } else {
        this.$toast(this.$t('请选择分配线索'))
      }
    },
    onFilter(params) {
      this.filterParams = formatParams(params, false)
      this.filterPanelVisible = false
      this.onRefresh()
      this.getAppointmentNum()
    },
    distributionFilter(params){
      const { saIdList } = params
      const ids = Object.keys(this.checkedObj)
      if (saIdList.length){
        const { name , value } = saIdList[0]
        getTestDriveAssign({
          empId:value,
          empName:name,
          ids,
          roleLogo:'1014002'
        }).then(res=>{
          this.$toast(this.$t('分配成功'))
          this.checkedObj={}
          this.onSearch()
        })
      } else {
        this.$toast(this.$t('请选择分配人员'))
      }
    },
    // 刷新线索数据
    onRefresh() {
      try {
        this.$refs.list.onRefresh()
        document.getElementById('scrollView').scrollTop = 0
      } catch (error) {

      }
    },
    fetchApi(page) {
      const { currentPage,pageSize } = page
      const { planArriveDate,appointmentType,searchStr,filterParams,tableType,roleCode } = this
      return getAppointmentPage({
        roleCode,
        pageNum: currentPage,
        pageSize,
        search:searchStr,
        ...filterParams,
        tableType,
        startDate: dateFormat(planArriveDate,'YYYY-MM-DD'),
        endDate: dateFormat(planArriveDate,'YYYY-MM-DD'),
      })
    },
    getAppointmentNum() {
      const { planArriveDate,appointmentType,searchStr,filterParams,roleCode } = this
      getAppointmentNum({
        roleCode,
        appointmentType,
        search:searchStr,
        ...filterParams,
        startDate: dateFormat(planArriveDate,'YYYY-MM-DD'),
        endDate: dateFormat(planArriveDate,'YYYY-MM-DD'),
      }).then(res=>{
        this.tabNum = res
      })
    },
    // 人员list
    getStaffList(roleLogo) {
      return baseDataServices.getStaffList({ roleLogo, dealerId: this.userInfo.dealers[0]?.id })
    },   
    getAllSeriesModels() {
      baseDataServices.getAllSeriesModels().then(res=>{
        this.modelList = arrayFormat(res,{ name:'name',value:'code',cb:(item)=>{ return { id: uuidv4(),modelList: item.models } } })
      })
    },
    //  确认排程
    async confirmScheduling(data) {
      console.log('data:',data)
      data.appointmentStartTime = dateFormat(data.appointmentStartTime, 'YYYY-MM-DD HH:mm:ss')
      data.appointmentEndTime = dateFormat(data.appointmentEndTime, 'YYYY-MM-DD HH:mm:ss')
      data.modelCode = data.vehicleCode
      // 直接排程
      data.id = this.appointmentInfo.id
      await driveUpdatePost(data)
      this.showScheduling = false
      this.onRefresh()
    },
    // 上门试驾，排程
    onEditSchedule(item){
      // 2023-11-22 00:00-23:59
      item.appointmentTimeShow = item.appointmentTime
      const [date,timeStr] = item.appointmentTime.split(' ')
      let [startTime,endTime] = timeStr.split('-')
      item.appointmentStartTime = date+' '+startTime+':00'
      item.appointmentEndTime = date+' '+endTime+':59'
      this.$set(this,'appointmentInfo',item)
      this.$set(this.appointmentInfo,'typeCode',item.type)
      this.showScheduling = true
    }
  }
}
</script>
<template>
  <div class="page" :style="{paddingBottom: isCheck? '50px':0}">
    <van-popup v-model="showPopup" position="top" closeable :style="{ height: '70%' }">
      <calendarVue v-if="showPopup" :appointmentType="appointmentType" :service="'getCalendarList'" :roleCode="roleCode" maxHeight="100%" :init-date="planArriveDate" @change="(val)=>(changeDate(val,true))"/>
    </van-popup>
    <div :style="{position: 'sticky', zIndex: 20, top: fromSalesAssistant ? '0px' : '44px'}">
      <search-bar-new
        :placeholder-text="$t('请输入客户姓名/电话')"
        :showScreen="true"
        @input="onInput"
        @search="onSearch"
      >
        <img :src="Object.values(filterParams).length > 0 ? filterActiveIcon : filterIcon" @click.stop="filterPanelVisible = true"/>
        <img :src="calendarIcon" @click.stop="showPopup = true"/>
      </search-bar-new>
      <weekdayCalendar  :init-date="getWeekdayDate" :tableType="tableType" :roleCode="roleCode" :appointmentType="appointmentType" @change="changeDate" />
      <div class="tabs">
          <template v-for="(item,index) in tabs">
            <template v-if="item.value === 'TO_BE_ASSIGN'">
              <div v-if="getMenu"  :key="item.value" v-btn-premission="'BTN-TEST-BE-ALLOCATED'" :class="{current: item.value === tableType}" class="tab" @click="onTabChange(item.value)">{{ item.name + (item.value ? `(${item.num || 0})` : '') }}</div>
            </template>
            <div v-else  :key="item.value" :class="{current: item.value === tableType}" class="tab" @click="onTabChange(item.value)">{{ item.name + (item.value ? `(${item.num || 0})` : '') }}</div>
          </template>
      </div>
    </div>
    <!-- <scroll class="scroll" height="auto"> -->
      <list ref="list" :key="tableType || 'ALL'" :fetchApi="fetchApi" >
        <template v-slot:default="{ item, index }">
          <card :data="item" :planArriveDate="planArriveDate" :tableType="tableType" :appointmentType="appointmentType" @openSchedule="onEditSchedule" @refresh="onRefresh">
            <template v-if="isCheck" slot="check">
              <van-checkbox :value="checkedObj[item.id]" class="check" shape="square" @click.stop="checkItem(item)"></van-checkbox>
            </template>
          </card>
        </template>
      </list>
    <!-- </scroll> -->
    <filter-panel ref="filterPanel" v-model="filterPanelVisible" :options="filterOptions" @submit="onFilter" />
    <BusinessFilters ref="filter" v-model="showDistribution" :options="distributionOptions"  :defaultActiveKey="defaultActiveKey"  @submit="distributionFilter"></BusinessFilters>
    <van-goods-action v-if="isCheck">
     <div class="bottom-btns">
      <div class="left">
        <span @click="checkedObj={}">{{ $t('取消') }}</span>
        <span @click="checkAll">{{ $t('全选') }}</span>
      </div>
      <div class="right" @click="next">{{ $t('分配') }}{{ getCheckLength ? `(${getCheckLength})`:'' }}</div>
     </div>
    </van-goods-action>
    <!-- 排程信息 -->
    <van-popup v-model="showScheduling" style="width:100%; max-width: 500px;height:100%">
      <UpdateVue v-if="showScheduling" :data="appointmentInfo" @confirm="confirmScheduling" @close="showScheduling=false"></UpdateVue>
    </van-popup>
  </div>
</template>
<style lang="less" scoped>
.tabs{
  font-size: 13px;
  display: flex;
  overflow-x: auto;
  padding: 8px 16px;
  background-color: #ffffff;
  .tab{
    flex: 1 0 auto;
    padding: 4px 12px;
    background-color: #f5f5f5;
    border-radius: 50px;
    // box-shadow: 0px 4px 12px 0px rgba(0,0,0,0.08);
    cursor: pointer;
    &:not(:last-child){
      margin-right: 12px;
    }
    &.current{
      background-color: #EED484;
      color: #B9921A;
    }
  }
}
.bottom-btns{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  line-height: 50px;
  .left{
    padding-left: 8px;
    >span{
      padding: 0 8px;
      cursor: pointer;
    }
  }
  .right{
    background-color: #EED484;
    height: 100%;
    width: 92px;
    text-align: center;    
    cursor: pointer;
  }
}
.check{
  position: absolute;
  top:50%;
  transform: translateY(-50%);
  right: 14px;
}
.my-calendar{
  /deep/ .van-calendar__body{
    overflow: hidden;
  }
}
</style>
